<template>
  <div>
    <v-row class="align-center align-content-center">
      <v-col
          class="col-sm-12 col-md-6 col-12"
          align-self="center"
          style="padding-top: 3%;"
      >
        <v-row
            align-content="center"
            class="stepper-cellphone__left-section-image"
        >
          <v-col
              class="col-md-12"
              align-self="center"
          >
            <v-img
                src="@/assets/img/yellow_pet_pen.svg"
                alt="Mascota"
                position="center center"
                contain
                max-height="300"
                min-height="300"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        class="col-sm-12 col-md-6 col-12"
        align-self="center"
      >
        <v-row>
          <v-col
            class="col-md-12"
            align-self="center"
          >
            <h2 class="divHomeTopMargin">
              Acepta los Términos y Condiciones
              y Políticas de privacidad
            </h2>
          </v-col>
        </v-row>
        <term-and-condition-checkbox v-model="agree"/>
        <v-row
          align-content-md="end"
          align-md="end"
          align="center"
          align-content="center"
          justify="center"
          justify-md="end"
        >
          <v-col
            class="
                col-auto
                text-md-end
                order-md-1 order-sm-1 order-0 order-lg-1
                float-right
              "
            align-self="end"
          >
            <v-btn
              bottom
              depressed
              rounded
              x-large
              class="btnAmarillo float-md-end"
              :disabled="!agree"
              @click="$emit('nextCard')"
            >
              Continuar
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TermAndConditionCheckbox from "@/components/steps/TermAndConditionCheckbox.vue";
export default {
  name: "TermAndConditionCard",
  components: {TermAndConditionCheckbox},
  props: ['value'],
  computed: {
    agree: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  methods: {

  }
}
</script>

<style scoped>

</style>