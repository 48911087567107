<template>
  <div>
    <v-row class="paddingBoxDesktop">
      <v-col
        align="left"
        justify="left"
        cols="1"
        style="padding-left: 0px"
      >
        <v-checkbox
          v-model="is_agree"
          color="#FFDD00"
        />
      </v-col>
      <v-col cols="11">
        <div
          class="lightPurple"
          align="center"
          justify="center"
        >
          Acepto los
          <a
            class="boldAndUnderlineText lightPurple"
            @click="showDialog('terminos')"
          >
            Términos y Condiciones
          </a>
          ,
          <a
            class="boldAndUnderlineText lightPurple"
            @click="showDialog('politicas')"
          >
            Políticas de Privacidad
          </a>
          y gestionar mi Atención al Cliente vía WhatsApp
          <img
            class="imageWpp"
            src="@/assets/img/icon_wpp.png"
            alt="wpp"
          >
          para asistencia de ventas, atención al Cliente y Cobranzas.
        </div>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogTerminos"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-toolbar
        dark
        color="#FFDD00"
      >
        <v-btn
          icon
          dark
          @click="dialogTerminos = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>TÉRMINOS Y CONDICIONES GENERALES</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-card style="overflow-y: scroll; max-height: 92vh">
        <terms-and-conditions />
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="dialogPoliticas"
        persistent
        transition="dialog-bottom-transition"
    >
      <v-toolbar
          dark
          color="#FFDD00"
      >
        <v-btn
            icon
            dark
            @click="dialogPoliticas = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS
        </v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-card style="overflow-y: scroll; max-height: 92vh">
        <privacy-policies />
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import TermsAndConditions from "@/views/solicitud/legal/TermsAndConditions.vue";
import PrivacyPolicies from "@/views/solicitud/legal/PrivacyPolicies.vue";
export default {
  name: "TermAndConditionCheckbox",
  components: {TermsAndConditions, PrivacyPolicies},
  props: ['value'],
  data() {
    return {
      dialogTerminos: false,
      dialogPoliticas: false,
    };
  },
  computed: {
    is_agree: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    showDialog(tipo) {
      if (tipo === "terminos") {
        this.dialogTerminos = true;
      } else {
        this.dialogPoliticas = true;
      }
    },
  }
}
</script>

<style scoped>

</style>