<template>
  <div>
    <div id="personal-information-data">
      <v-form ref="form" v-model="valid" lazy-validation>
        <div v-if="phoneValidationStep">
          <v-main v-show="!show_validation_code">
            <v-row class="align-center align-content-center">
              <v-col class="col-sm-12 col-md-6 col-12" align-self="center">
                <v-row>
                  <v-col class="col-md-12" align-self="center">
                    <h2 class="divHomeTopMargin">
                      Validá tu número de celular
                    </h2>
                    <h3 class="subtitle">TE ENVIAREMOS UN CÓDIGO POR SMS</h3>
                  </v-col>
                </v-row>
                <v-row
                  align-content="center"
                  class="stepper-cellphone__left-section-image"
                >
                  <v-col class="col-md-12" align-self="center">
                    <v-img
                      src="@/assets/img/yellow_pet_confidential.svg"
                      alt="Mascota"
                      contain
                      max-height="300"
                      min-height="300"
                      position="center center"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col>
                <v-row>
                  <v-col
                    class="stepper-cellphone__input"
                    align="center"
                    justify="center"
                  >
                    <v-text-field
                      v-model="phoneNumber"
                      label="Nro. de celular ingresado:"
                      color="#FphoneNumber"
                      hint="Ejemplo: 1134567685"
                      class="
                        inputFieldCelular
                        inputFieldCelularCenter
                        centered-input
                      "
                      :class="classError"
                      maxlength="10"
                      counter
                      type="number"
                      :rules="[rules.counter, rules.required]"
                      required
                      :error-messages="errorMessages"
                      @keypress="isNumber($event)"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="stepper-cellphone__btn-send">
                <v-btn
                  v-show="!show_validation_code"
                  bottom
                  depressed
                  rounded
                  x-large
                  class="btnAmarillo"
                  :disabled="!valid"
                  @click="checkUser()"
                >
                  Enviar SMS
                </v-btn>
              </v-col>
            </v-row>
          </v-main>

          <v-main v-show="show_validation_code">
            <v-row class="align-center align-content-center">
              <v-col class="col-sm-12 col-md-6 col-12" align-self="center">
                <v-row>
                  <v-col class="col-md-12" align-self="center">
                    <h2>Ingresá el código enviado por SMS</h2>
                    <h3 class="subtitle">
                      ESCRIBÍ EL CÓDIGO DE 4 DÍGITOS QUE RECIBISTE
                    </h3>
                  </v-col>
                </v-row>
                <v-row
                  align-content="center"
                  class="stepper-cellphone__left-section-image"
                >
                  <v-col class="col-md-12" align-self="center">
                    <v-img
                      src="@/assets/img/yellow_pet_confidential.svg"
                      alt="Mascota"
                      position="center center"
                      contain
                      max-height="300"
                      min-height="300"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col>
                <p class="paddingFooterDesktop">Código de verificación</p>
                <v-otp-input
                  v-model="code"
                  type="number"
                  length="4"
                  plain
                  class="inputOTP paddingBoxDesktop"
                  :class="classCodeStatus"
                  color="#FFF000"
                  @input="validate()"
                />
                <div>
                  <p>El código puede demorar unos instantes.</p>
                </div>

                <div>
                  <v-snackbar
                    v-model="snackbarValidateCodeShow"
                    :bottom="true"
                    :color="snackbarValidateCodeColor"
                    timeout="4000"
                  >
                    {{ snackbarValidateCodeText }}
                    <template #action="{ attrs }">
                      <v-btn
                        dark
                        text
                        v-bind="attrs"
                        @click="snackbarValidateCodeShow = false"
                      >
                        Cerrar
                      </v-btn>
                    </template>
                  </v-snackbar>
                </div>
                <v-row style="zoom: 60%">
                  <v-col align="center" justify="center">
                    <v-col v-show="codeStatus === codeInvalid" class="sa">
                      <div class="sa-error">
                        <div class="sa-error-x">
                          <div class="sa-error-left" />
                          <div class="sa-error-right" />
                        </div>
                        <div class="sa-error-placeholder" />
                        <div class="sa-error-fix" />
                      </div>
                    </v-col>

                    <div
                      v-show="codeStatus === codeValid"
                      class="swal-icon swal-icon--success"
                    >
                      <span
                        class="
                          swal-icon--success__line
                          swal-icon--success__line--long
                        "
                      />
                      <span
                        class="
                          swal-icon--success__line swal-icon--success__line--tip
                        "
                      />

                      <div class="swal-icon--success__ring" />
                      <div class="swal-icon--success__hide-corners" />
                    </div>
                  </v-col>
                </v-row>

                <term-and-condition-checkbox v-model="is_agree" />
              </v-col>
            </v-row>
            <v-row
              align-content-md="end"
              align-md="end"
              align="center"
              align-content="center"
              justify="center"
              justify-md="end"
            >
              <v-col
                class="col-auto text-md-end float-right order-1 order-md-0"
                align-self="end"
              >
                <v-btn
                  bottom
                  depressed
                  rounded
                  x-large
                  class="btnTransparente"
                  :disabled="!timeToResend"
                  @click="reSendCode()"
                >
                  Reenviar código {{ timerFormat }}
                </v-btn>
              </v-col>
              <v-col
                class="
                  col-auto
                  text-md-end
                  order-md-1 order-sm-1 order-0 order-lg-1
                  float-right
                "
                align-self="end"
              >
                <v-btn
                  v-show="
                    show_validation_code && codeStatus === codeValid && is_agree
                  "
                  bottom
                  depressed
                  rounded
                  x-large
                  class="btnAmarillo float-md-end"
                  @click="next()"
                >
                  Continuar
                </v-btn>
              </v-col>
            </v-row>
            <div v-show="is_agree === false">
              <v-snackbar
                v-model="snackbarShow"
                :bottom="true"
                color="#FF0000"
                timeout="4000"
              >
                {{ snackbarText }}
                <template #action="{ attrs }">
                  <v-btn dark text v-bind="attrs" @click="snackbarShow = false">
                    Cerrar
                  </v-btn>
                </template>
              </v-snackbar>
            </div>
          </v-main>
        </div>

        <v-main v-else>
          <term-and-condition-card
            v-model="agree_terms_condition"
            @nextCard="next"
          />
        </v-main>
      </v-form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { steps } from "@/helper/steps";
import { messageClient } from "@/helper/messageClient";
import TermAndConditionCard from "@/components/steps/TermAndConditionCard.vue";
import TermAndConditionCheckbox from "@/components/steps/TermAndConditionCheckbox.vue";

export default {
  name: "AutogestionDatosPersonales",
  components: {
    TermAndConditionCheckbox,
    TermAndConditionCard,
  },
  data: () => ({
    phoneNumber: null,
    show_validation_code: false,
    agree_terms_condition: false,
    is_agree: false,
    code: "",
    sendCode: false,
    radioGroup: 1,
    codeStatus: 0,
    codeInvalid: 1,
    codeValid: 2,
    dialogPoliticas: false,
    dialogTerminos: false,
    visible: false,
    valid: true,
    snackbarShow: false,
    snackbarText: "",
    classCodeStatus: "",
    labelButton: "Enviar SMS",
    showResendCode: false,
    rules: {
      counter: (value) =>
        (!!value && value.length === 10) ||
        "Este campo debe contener 10 caracteres. Ejemplo: 1134567685",
      required: (value) => !!value || "Este campo es requerido.",
    },
    errorMessages: "",
    classError: "",
    TIMER_COUNT: 30,
    snackbarValidateCodeShow: false,
    snackbarValidateCodeText: "",
    snackbarValidateCodeColor: "",
    timePassed: null,
    timerInterval: null,
    timeToResend: false,
    phoneValidationStep: process.env.VUE_APP_PHONE_VALIDATE === "1",
  }),
  computed: {
    ...mapGetters(["getPhone"]),
    timerFormat() {
      const timeLeft = this.timeLeft;
      if (this.timePassed === 0) {
        return "";
      }
      const minutes = Math.floor(timeLeft / 60);
      let seconds = timeLeft % 60;

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `( ${minutes}:${seconds} )`;
    },
    timeLeft() {
      return this.TIMER_COUNT - this.timePassed;
    },
    isAvailable() {
      return this.$store.getters.getAvailable;
    },
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.TIMER_COUNT--;
          }, 1000);
        }
      },
      immediate: true,
      dialog(val) {
        val || this.close();
      },
    },
    timeLeft(newValue) {
      if (newValue === 0) {
        this.timeToResend = true;
        this.onTimesUp();
      }
    },
    isAvailable(newVal) {
      if (!this.continueClick) {
        return;
      }
      if (newVal === -1) {
        this.revalidateUser();
      }

      if (newVal === 2) {
        this.$router.replace({ name: "send_to_app" });
      }
      if (this.phoneValidationStep && newVal === 1 && !this.sendCode) {
        this.closeLoader();
        this.sendMessageToPhone();
      }
      if (!this.phoneValidationStep && newVal === 1) {
        this.goToNextStep();
      }
      if (newVal !== null && (newVal > 2 || newVal === 0)) {
        this.$router.replace({
          name: "request_failed",
          params: { codeDeny: newVal },
        });
      }
    },
  },
  created() {
    console.log("Componente Datos Personales creado");
    this.is_agree = false;
    this.show_validation_code = false;
    this.phoneNumber = this.$store.getters.getPhone;
  },
  methods: {
    ...mapActions(["openLoader", "closeLoader", "isAvailableUser"]),
    ...mapGetters([
      "getPreffix",
      "getCardStepDocumentation",
      "getDataUser",
      "getEmail",
    ]),
    next() {
      this.is_agree = !this.phoneValidationStep
        ? this.agree_terms_condition
        : this.is_agree;
      let canGoToNextStep = true;
      if (!this.is_agree) {
        this.snackbarShow = true;
        this.snackbarText =
          "Para continuar es necesario leer y aceptar los Términos y Condiciones y la Política de Privacidad";
        return;
      }
      if (this.agree_terms_condition) {
        canGoToNextStep = this.checkUser();
        this.$gtm.trackEvent({
          noninteraction: false,
          event: `${this.getPreffix()}_TYC_ACCEPT`,
          id_person: this.$store.getters.getUserForm,
        });
      }
      canGoToNextStep ? this.goToNextStep() : "";
    },
    sendMessageToPhone() {
      this.$gtm.trackEvent({
        noninteraction: false,
        event: `${this.getPreffix()}_INPUT_DEV`,
        id_person: this.$store.getters.getUserForm,
      });
      this.openLoader();
      this.$apiserver
        .sendMessagePhoneCode(this.$business.PREFFIX_PHONE + this.phoneNumber)
        .then(() => {
          this.sendCode = true;
          this.show_validation_code = true;
          this.startTimer();
          this.closeLoader();
          this.snackbarValidateCodeShow = true;
          this.snackbarValidateCodeText = "Codigo Enviado";
          this.snackbarValidateCodeColor = "green";
        })
        .catch((e) => {
          this.$store.commit("ERROR_MODAL", true);
          this.code = false;
          console.log(e.message);
        });
    },
    reSendCode() {
      this.code = "";
      this.codeStatus = 0;

      this.sendMessageToPhone();
      this.classCodeStatus = "";
    },
    validate() {
      if (this.code.length === 4) {
        this.openLoader();
        this.classCodeStatus = "";
        this.$apiserver
          .validatePhoneCode(
            this.$business.PREFFIX_PHONE + this.phoneNumber,
            this.code
          )
          .then((response) => {
            this.$gtm.trackEvent({
              noninteraction: false,
              event: `${this.getPreffix()}_INPUT_PIN`,
              id_person: this.$store.getters.getUserForm,
            });
            if (response.error !== "") {
              this.$store.commit("ERROR_MODAL", true);
              console.log(response.message);
              return;
            } else {
              if (response.status === 200) {
                this.sendCode = false;
                this.codeStatus = this.codeValid; // codigo valido
                this.classCodeStatus = "text-success";
                (this.snackbarValidateCodeShow = true),
                  (this.snackbarValidateCodeText = "Codigo Verificado"),
                  (this.snackbarValidateCodeColor = "green");
              } else {
                this.codeStatus = this.codeInvalid; // codigo invalido
                this.classCodeStatus = "text-danger";
                (this.snackbarValidateCodeShow = true),
                  (this.snackbarValidateCodeText =
                    "Codigo Incorrecto, intenta nuevamente o pedi un codigo nuevo"),
                  (this.snackbarValidateCodeColor = "red");
                this.code = "";
              }
            }
          })
          .catch((e) => {
            this.$store.commit("ERROR_MODAL", true);
            this.code = false;
            console.log(e.message);
          });
        this.closeLoader();
      } else {
        this.classCodeStatus = "";
      }
    },
    startTimer() {
      this.timeToResend = false;
      this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
    },
    onTimesUp() {
      clearInterval(this.timerInterval);
      this.timePassed = 0;
    },
    checkUser() {
      let availableUser = this.$store.getters.getAvailable;
      if (availableUser === -1) {
        this.openLoader();
        this.revalidateUser();
        return false;
      }
      if (availableUser === null) {
        this.continueClick = true;
        this.openLoader();
        return false;
      }
      if (this.phoneValidationStep && availableUser === 1) {
        this.sendMessageToPhone();
        return true;
      }

      if (!this.phoneValidationStep && availableUser === 1) {
        return true;
      }
      if (availableUser !== 1) {
        this.$router.replace({
          name: "request_failed",
          params: { codeDeny: availableUser },
        });
        return false;
      }
    },
    revalidateUser() {
      this.continueClick = true;
      let form = this.getDataUser();
      let formFormat = {
        dni: form._dni,
        cuit: form._cuit,
        firstName: form._name === "" ? form._fullname : form._name,
        lastName: form._lastName === "" ? form._fullname : form._lastName,
        name: form._fullname,
        email: this.getEmail(),
        birthday: form._birthday,
      };
      this.$apiserver
        .checkCredit(formFormat.dni)
        .then((response) => {
          this.isAvailableUser(response.code);

          if (response.code === 2) {
            this.$router.replace({ name: "send_to_app" });
            return;
          }
          if (
            response.code !== 1 &&
            response.code !== null &&
            response.code !== -1
          ) {
            this.$router.replace({
              name: "request_failed",
              params: { codeDeny: response.code },
            });
          } else {
            formFormat.name = response.firstName;
            formFormat.lastName = response.lastName;
            formFormat.birthday = response.birthday;

            this.setClient(formFormat);
            this.$apiserver.setDataUser(formFormat);
            this.$gtm.trackEvent({
              noninteraction: false,
              event: "WEB_ARG_CREDIT_APPROVED",
              id_person: this.$store.getters.getUserForm,
            });
          }
        })
        .catch((e) => {
          this.continueClick = false;
          this.$store.commit("ERROR_MODAL", true);
          this.$store.commit("SET_ERROR_MODAL_MSG", "");
          this.$gtm.trackEvent({
            noninteraction: false,
            event: "WEB_ARG_ERROR_CONFIRM_PERSON",
            id_person: this.$store.getters.getUserForm,
          });
          console.log(e);
        })
        .finally(() => {
          this.closeLoader();
        });
    },
    goToNextStep() {
      let step = this.getCardStepDocumentation() === 7 ? `5` : `4`;
      this.closeLoader();
      this.$emit("send-message", step);
    },
  },
};
</script>
<style>
.inputFieldCelular .v-text-field__slot input {
  border-color: #ffdd00 !important;
  font-weight: bold !important;
  color: #6031da !important;
  font-size: 1.5625rem !important;
  text-align: center !important;
}

.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: #ffdd00;
}

.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: inherit;
  border-style: solid;
  border-width: medium 0 0 0;
}

.theme--light.v-text-field:not(.v-input--has-state):hover
  > .v-input__control
  > .v-input__slot:before {
  border-color: #ffdd00;
}

.v-input__is-focused {
  border-color: #ffdd00 !important;
}

.v-input__slot {
  font-size: 25px !important;
}

.v-text-field.centered-input .v-label {
  left: 50% !important;
  transform: translateY(-20px) translateX(-50%) !important;
}

.v-toolbar__content {
  color: #000000 !important;
}
</style>